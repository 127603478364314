import { Link } from "@remix-run/react"
import type { ReactNode } from "react"

const className = "underline text-link"
export const linkClassName = className

export function ExternalLink({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) {
  return (
    <a href={to} target="_blank" rel="noreferrer" className={linkClassName}>
      {children}
    </a>
  )
}

export function InternalLink({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) {
  return (
    <Link to={to} className={linkClassName}>
      {children}
    </Link>
  )
}

export function ExternalLinkPrimaryButtton({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) {
  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer"
      className="inline-flex w-56 justify-center rounded-lg
        bg-slate-900 py-2.5
        px-4 text-sm
        font-semibold text-white
        no-underline
        hover:bg-slate-700
        not-prose
      "
    >
      {children}
    </a>
  )
}

export function InternalLinkSecondaryButton({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) {
  return (
    <Link
      to={to}
      className="inline-flex w-56 justify-center rounded-lg
            border border-solid  border-slate-900 bg-white
          py-2.5 px-4
          text-sm font-semibold text-slate-900
          hover:border-slate-700
          hover:underline
          "
    >
      {children}
    </Link>
  )
}
